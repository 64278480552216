import React from 'react';
import {Row, Col} from 'react-bootstrap';
import Layout from "../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class Elements extends React.Component {
    render() {
        return (
            <Layout>
                <Row>
                    <Col>
                        <h1>THE ELEMENTS - Martial Arts Seminar</h1>
                        <p>Sunday 15th May 2011
                            *OPEN TO ALL STYLES AND ABILITIES</p>

                        <p>Time: 11am - 3pm
                            Cost: ADULTS £15, KIDS £7.50. Pay on the door.
                            Address: Almondbury Sports Centre, Fernside Ave, Huddersfield, HD5 8PQ
                            Contact: Simon Golland, Tel: 0790 480 5451
                            BYO: Refreshment / Snacks</p>

                        <p>4 x 30 minute sessions in:
                            Goju Ryu with Sensei Simon Golland
                            Kempo Ju Jitsu with Sensei Richard Dickinson
                            KAPAP with KAPAP Academy Staff Instructor Craig Welsh
                            Practical Arnis with Sifu Rebecca Kane</p>

                        <p>1.5 hour main event with Sensei Graham Ravey.
                            The last opportunity to train with Sensei Graham Ravey before he leaves the UK!
                            Internationally respected Goju Ryu expert and accomplished martial artist, Graham has
                            dedicated 44 years of his life to the arts, spending 12 years living in Japan rigorously
                            training in Goju Ryu throught the 70's/80's. Tales of this journey can be read in his book
                            Yoyogi Dojo '74.</p>
                    </Col>
                    <Col>
                        <StaticImage src="../../images/elements.jpg" alt="logo"/>
                    </Col>
                </Row>
            </Layout>);
    }
}

export default Elements;
